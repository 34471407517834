import React, { useEffect } from "react";
import {
  getAllGateways,
  setSelectedGateway,
} from "../../../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import DepositGateway from "./depositGateway";
import { Navigate, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import DepositImg from "../../../../assets/images/icons/dc.png";
import bankImg from "../../../../assets/images/icons/bank.png";
import { getActiveBankDetails } from "../../../../redux/app/actions";
import BackIcon from "../../../../assets/svg/BackIcon";
import { Container } from "react-bootstrap";

const GatewaysList = () => {
  const dispatch = useDispatch();
  const { gatewaysList } = useSelector((state) => state.user);
  const { bankDetails } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);
  const isDemoUser = user?.mstruserid === process.env.REACT_APP_DEMO_USER_ID;
  const isAutoGatewayExist = gatewaysList?.deposits?.some(
    (f) => f?.type === "auto"
  );

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAllGateways());
    dispatch(getActiveBankDetails());
  }, []);

  const handleGatewayClick = (item) => {
    dispatch(setSelectedGateway(item));
    navigate(`/deposit/${item.name}`);
  };

  const handleBankTransferClick = () => {
    navigate(`/deposit/bank_transfer`);
  };
  return (
    <div>
      {isDemoUser && <Navigate to="/" />}
      <HeaderAfterLogin />
      <main className="main">
        <Container>
          <div className="pagesHeading">
            <h4>Deposit</h4>
            <a className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </a>
          </div>

          <div className="gatewaySection">
            <div className="gatewaypart">
              <Tabs
                // defaultActiveKey={isAutoGatewayExist ? "Deposit" : "bank"}
                defaultActiveKey="Deposit"
                id="uncontrolled-tab-example"
                className=""
              >
                <Tab eventKey="Deposit" title="Online Deposit">
                  {gatewaysList?.deposits
                    ?.sort((a, b) => a?.order - b?.order)
                    ?.filter((f) => f?.type === "auto")
                    .map((item, index) => {
                      return (
                        <div
                          className="cards1"
                          onClick={() => {
                            handleGatewayClick(item);
                          }}
                          key={index}
                        >
                          <div className="card-icon">
                            <img src={item?.logo || DepositImg} />
                          </div>

                          <p style={{ color: "#333" }}>{item?.name}</p>
                        </div>
                      );
                    })}
                </Tab>

                <Tab eventKey="bank" title="Bank Transfer">
                  {bankDetails && (
                    <div
                      className="cards1"
                      onClick={() => {
                        handleBankTransferClick();
                      }}
                    >
                      {" "}
                      <div className="card-icon">
                        <img src={bankImg} style={{ width: "66px" }} />
                      </div>
                      <p style={{ color: "#333" }}>{"Bank Transfer"}</p>
                    </div>
                  )}

                  {gatewaysList?.deposits
                    ?.sort((a, b) => a?.order - b?.order)
                    ?.filter((f) => f?.type === "manual")
                    .map((item, index) => {
                      return (
                        <div
                          className="cards1"
                          onClick={() => {
                            handleGatewayClick(item);
                          }}
                          key={index}
                        >
                          <div className="card-icon">
                            <img src={item?.logo || DepositImg} />
                          </div>
                          <p style={{ color: "#333" }}>{item?.name}</p>
                        </div>
                      );
                    })}
                </Tab>
              </Tabs>
            </div>
          </div>
        </Container>
      </main>
    </div>
  );
};
export default GatewaysList;
